import React, { lazy, Suspense, } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { Spinner } from './components';
import { Startup } from './startup';

// application container where contents shall be dynamically rendered by react dom...
const applicationContainer = document.getElementById('application-container');
const root = createRoot(applicationContainer);
// lazily importing application page...
const Application = lazy(() => import('./pages').then(module => ({ default: module.Application, })));
// lazily importing error page...
const Error = lazy(() => import('./pages').then(module => ({ default: module.Error })));

const onBeforeRenderAsync = async () => {
  const startup = new Startup();
  const result = await startup.configureAsync();

  return {
    status: 200,
    message: 'Initial configuration completed successfully.',
    ...result,
  };
};

onBeforeRenderAsync().then(result => {
  // removes the spinner from root...
  document.getElementById('container-d422aee5').remove();

  const { status, message, reduxStore, persistedStore, } = result;

  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <Suspense fallback={<Spinner />}>
          {status === 200 && <Provider store={reduxStore}>
            <PersistGate loading={<Spinner />} persistor={persistedStore}>
              <Application />
            </PersistGate>
          </Provider>}
          {status !== 200 && <Error statusCode={status} message={message} />}
        </Suspense>
      </BrowserRouter>
    </React.StrictMode>
  );
});
