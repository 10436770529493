import { useState } from 'react';
import { StringUtilities } from '../utilities';

export const useExtendedState = (defaultValue = {}, useInputValueChangeListener = true) => {
  const [data, setData] = useState(defaultValue);

  const updateData = entry => {
    setData({
      ...data,
      ...entry,
    });
  };

  /**
   * @param {{
   * target: {
   * type: String,
   * name: String,
   * value: *,
   * }}} event 
   * @returns 
   */
  const onInputValueChanged = async event => {
    if (!useInputValueChangeListener) { return; }

    const targetName = event?.target?.name ?? undefined;

    if (StringUtilities.isUndefinedOrNull(targetName)) { return; }

    const targetType = event?.target?.type ?? undefined;
    let targetValue = event?.target?.value ?? undefined;

    // if event was generated by input type number...
    if (targetType === 'number') {
      // converts value to number...
      const _targetValue = Number(targetValue);

      // if value is actually a number, we shall assign the converted value...
      if (!isNaN(_targetValue)) { targetValue = _targetValue; }
    }

    // if event was generated by input type checkbox...
    if (targetType === 'checkbox') {
      const isChecked = event?.target?.checked ?? false;

      targetValue = isChecked;
    }

    updateData({
      [targetName]: targetValue,
    });
  };

  return [data, updateData, onInputValueChanged, setData,];
};
